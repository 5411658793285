<template>
  <div class="about">
    <h1>Sync history</h1>
    <div class="validation-not-valid">
      <div class="wrapper">
        <table>
          <tr>
            <th style="padding-left: 5px;">Status</th>
            <th>Published GTIN</th>
            <th>Date</th>
            <th style="width: 74%; text-align: left;">Details</th>
          </tr>
          <template style="width: 100%" v-for="(result, index) in validations.slice().reverse()" :class="index %2 === 0 ? 'validation-entry ' + result.valid + ' even' : 'validation-entry ' + result.valid + ' odd'">
            <tr :key="index" :class="index %2 === 0 ? 'dark' : 'light'">
              <td style="padding-left: 5px;"><img src="../assets/img/check-green.png" v-if="result.status === 'ACCEPTED'"/><img src="../assets/img/cross-red.png" v-if="result.status === 'INVALID'"/></td>
              <td style="padding: 10px 0px;">{{ result.publishedGTIN }}</td>
              <td style="padding: 10px 0px;">{{ result.validationDate.date }}</td>
              <td :style="result.details !== null && result.details.length > 0 ? '' : 'padding: 10px 0px;'">
                <table v-if="result.details !== null && result.details.length > 0" class="table-details">
                  <thead>
                    <tr>
                      <th style="padding-left: 5px;">GTIN</th>
                      <th>ERROR CODE</th>
                      <th>DESCRIPTION</th>
                      <th>DATE/TIME</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(d, index) in result.details" :key="index" :class="index % 2 !== 0 ? 'light' : 'dark'">
                      <td>{{ index }}{{ result.publishedGTIN }}</td>
                      <td>{{ d.errorCode }}</td>
                      <td>{{ d.errorDescription }}</td>
                      <td>{{ d.errorDateTime }}</td>
                    </tr>
                  </tbody>
                </table>
                <span v-if="result.details !== null && result.details.length === 0">Accepted and Synced</span>
              </td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import axios from 'axios'
import { Config } from '../../config/index.js'
import '../assets/scss/style.scss'

export default {
  name: 'Home',
  data: function () {
    return {
      validations: []
    }
  },
  methods: {
    downloadGs1Data (id) {
      axios.get(Config.APP_VALIDATION_API + '/convert/' + id + '/xls/gs1')
        .then(response => {
          const blob = new Blob([response.data], { type: 'application/xml' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'GS1_Converted.xml'
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    downloadJsonData (id) {
      axios.get(Config.APP_VALIDATION_API + '/convert/' + id + '/xls/json')
        .then(response => {
          const blob = new Blob([JSON.stringify(response.data)], { type: 'application/json' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'JSON_Converted.json'
          link.click()
          URL.revokeObjectURL(link.href)
        })
    },
    validate (id) {
      axios.get(Config.APP_VALIDATION_API + '/api/validate/' + id)
        .then(response => {
          var data = JSON.stringify(response.data.payload)
          const blob = new Blob([data], { type: 'text/json' })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = 'report.json'
          link.click()
          URL.revokeObjectURL(link.href)
        })
    }
  },
  mounted: function () {
    axios
      .get(Config.APP_VALIDATION_API + '/api/validations')
      .then(response => {
        for (var i in response.data.payload) {
          var val = response.data.payload[i]
          if (val.details !== null) {
            val.details = JSON.parse(val.details)
          }
        }
        this.validations = response.data.payload
      })
  }
}
</script>
