<template>
  <div id="app">
      <div id="nav">
        <router-link to="/">Home</router-link> |
        <router-link to="/validations">Sync history</router-link> |
        <router-link to="/logout">Logout</router-link>
      </div>
    <component v-bind:is="comp">
    </component>
  </div>
</template>

<script>

import Home from '../Home.vue'
import Validations from '../Validations.vue'

export default {
  name: 'BasePrivate',
  data: function () {
    return {
    }
  },
  props: {
    comp: { type: String, required: true }
  },
  components: {
    Home,
    Validations
  }
}
</script>
